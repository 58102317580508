.shoppingcart {
  display: flex;
  padding: 20px;
  background-color: white;
  height: max-content;
  min-height: calc(100vh - 120px);
  padding-bottom: 30px;
}

@media only screen and (max-width: 1250px) {
  .shoppingcart {
    display: block;
  }
}

.tablehead {
  min-width: 4vw;
  font-size: larger;
}

.tablehead__product {
  display: inline-block;
  min-width: 115px;
  padding-left: 10px;
  font-size: larger;
}

.tablehead__remove {
  min-width: 1vw;
}

.tablehead {
  align-self: center;
  align-items: center;
  align-content: center;
}
