.shoppingcart__product {
  display: flex;
  margin-left: -20px;
  margin-bottom: -10px;
  margin-top: -10px;
  margin-right: -40px;
}

.shoppingcart__image {
  user-select: none;
  max-height: 100px;
  width: 100%;
  object-fit: contain;
  display: inline;
  overflow: hidden;
  mix-blend-mode: multiply;
}

.shoppingcart__title {
  margin-right: 10px;
  padding: 10px;
  border-bottom: 1px solid lightgray;
  margin-bottom: 20px;
}

.shoppingcart__producttext {
  display: inline-block;
  width: 160px;
}

.clearicon {
  transform: scale(1.2);
}
.clearicon:hover {
  color: rgb(236, 61, 61);
  transform: scale(1.5);
}
