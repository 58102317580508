.homepage {
  height: 100vh;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0)
    ),
    url("../../img/background.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
}

.homepage__logo {
  width: 300px;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 1%;
  margin-top: 1%;
}

.homepage__background {
  width: 100%;
  z-index: -1;
  margin-bottom: -150px;
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

.homepage__text {
  text-align: center;
  color: #ffffff;
  margin-bottom: 40px;
  font-size: 45px;
  letter-spacing: 1px;
  font-family: Arial, Helvetica, sans-serif;
}

.homepage__searchbar {
  text-align: center;
  height: 40px;
  width: 70%;
  padding: 10px;
  font-size: 25px;
}

.homepage__button {
  text-align: center;

  height: 60px;
  width: 150px;
  background: black;
  border: none;
  color: #fff;
  font-size: large;
}

.homepage__button:hover {
  background: rgb(48, 48, 48);
  cursor: pointer;
}

.homepage__form {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  padding: 30px 50px;
}
