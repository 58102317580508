.orders {
  padding: 20px 80px;
  overflow-y: auto;
}

.orders__order {
  overflow-y: auto;
}

.orders__order > h1 {
  margin: 30px 0;
}

.orders__initLoad {
  padding-top: 25vh;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.orders__newLoad {
  display: flex;
  width: 100%;
  justify-content: center;
}
