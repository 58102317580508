.restaurant {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 1800px;
}

.restaurant__header {
  margin-left: 10px;
  margin-right: 10px;
}
.restaurant__row {
  display: flex;
  z-index: 1;
  width: 100%;
}

.restaurant__banner {
  margin-bottom: 10px;
  width: 100%;
  z-index: -1;
}

.restaurant__loading {
  padding-top: 1vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
