.product {
  margin: 10px;
  padding: 20px;
  padding-bottom: 80px;
  width: 100%;
  max-height: 100vh;
  min-width: 200px;
  background-color: white;
  z-index: 1;
  position: relative;
  transition: 0.3s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.product:hover {
  -webkit-box-shadow: 0 8px 16px 3px rgba(0, 0, 0, 0.4);
  box-shadow: 0 4px 16px 3px rgba(0, 0, 0, 0.4);
}

.product__rating {
  display: flex;
}

.product > img {
  user-select: none;
  max-height: 200px;
  width: 100%;
  object-fit: contain;
  margin-bottom: 15px;
  transition: 0.3s;
}

.product:hover > img {
  transform: scale(1.1);
}

.product > button {
  position: absolute;
  bottom: 20px;
  right: 10px;
  border: 1px solid;
  margin-top: 10px;
  color: white;
  margin-top: 20px;
  margin-right: 20px;
  margin-bottom: 0px;
  height: 40px;
  width: 150px;
  border-radius: 20px;
  background-color: black;
}

.product > button:hover {
  filter: brightness(110%);
}

.product > button:active {
  transform: scale(0.9);
}

.product__price {
  margin-top: 5px;
}

.product__info {
  margin-bottom: 15px;
}

.product__ingredients {
  font-style: italic;
}
