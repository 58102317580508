.checkout {
  background-color: white;
}

.checkout__container > h1 {
  text-align: center;
  padding: 10px;
  font-weight: 400;
  background-color: rgb(234, 237, 237);
  border-bottom: 1px solid lightgray;
}

.checkout__container > h1 a {
  text-decoration: none;
}

.checkout__section {
  display: flex;
  padding: 20px;
  margin: 0 20px;
  border-bottom: 1px solid lightgray;
}

.checkout__items {
  flex: 1;
}

.payment__section {
  display: flex;
  padding: 20px;
  margin: 0 20px;
  border-bottom: 1px solid lightgray;
}

.payment__title {
  /*flex: 0.2;*/
  width: 230px;
}

.payment__details {
  flex: 1;
}

.payment__details > form {
  max-width: 400px;
}

.payment__details > h3 {
  padding-bottom: 20px;
}

.payment__details > form > div > button {
  background: #ffc534;
  border-radius: 2px;
  width: 100%;
  height: 30px;
  border: 1px solid;
  font-weight: bolder;
  margin-top: 10px;
  color: white;
  border-radius: 20px;
  background-color: black;
}

.payment__details > form > div > button:hover {
  background: rgb(48, 48, 48);
}

.payment__details > form > div > button:active {
  transform: scale(0.9);
}
