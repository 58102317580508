.header {
  height: 70px;
  display: flex;
  align-items: center;
  background-color: black;
  position: sticky;
  top: 0;
  z-index: 100;
}

.header__logo {
  width: 150px;
  object-fit: contain;
  margin: 0 20px;
  margin-top: 10px;
}

.header__search {
  display: flex;
  flex: 1;
  align-items: center;
  border-radius: 24px;
  position: relative;
}

.header__searchInput {
  height: 12px;
  padding: 10px;
  padding-left: 35px;
  border: none;
  width: calc(100% - 75px);
  overflow: hidden;
  position: absolute;
  border-radius: 15px;
}

.header__searchIcon {
  position: absolute;
  margin-top: -11px;
  margin-left: 8px;
  fill-opacity: 0.5;
}

.header__optionLine {
  font-size: 13px;
  font-weight: 700;
}

.header__optionBasket {
  display: flex;
  align-items: center;
  color: white;
}

.header__basketCount {
  margin-left: 10px;
  margin-right: 10px;
}

.header__nav {
  display: flex;
  justify-content: space-evenly;
}

.header__option {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  color: white;
}
