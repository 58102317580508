.subtotal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 400px;
  padding: 20px;
  background-color: #f3f3f3;
  border: 1px solid #dddddd;
  border-radius: 3px;
  margin-left: 2vw;
  margin-top: 84px;
  position: fixed;
}

@media only screen and (max-width: 1250px) {
  .subtotal {
    margin-top: 20px;
    margin-left: 0px;
    position: static;
  }
}

.subtotal__gift {
  display: flex;
  align-items: center;
}

.subtotal__gift > input {
  margin-right: 5px;
}

.subtotal > button {
  background: #ffc534;
  border-radius: 2px;
  width: 80%;
  height: 35px;
  border: 1px solid;
  margin-top: 20px;
  color: white;
  border-radius: 20px;
  background-color: black;
  align-self: center;
}

.subtotal > button:active {
  transform: scale(0.9);
}

.subtotal > button:hover {
  background: rgb(48, 48, 48);
}

.details > h3 {
  margin-top: 15px;
  float: none;
}
.details > input {
  margin-top: 5px;
}

.details > label {
  margin-right: 10px;
}

.details #location {
  width: 300px;
  float: none;
}

.google__image {
  background-color: white;
  padding-right: 256px;
}

.subtotal__location {
  height: 30px;
  background-color: white;
  width: 94%;
  border: 2px solid;
  border-color: #212121 #767676 #767676 #212121;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 6px;
  padding-left: 10px;
  padding-right: 10px;
}

.subtotal__reservationTime {
  height: 25px;
  background-color: white;
  display: block;
  border: 2px solid;
  border-color: #212121 #767676 #767676 #212121;
  border-radius: 6px;
  padding-left: 10px;
}

.autocomplete-dropdown {
  width: 400px;
}
